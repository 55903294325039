import React from 'react';
import css from './EnvironmentLabel.APP_TARGET.scss';
import text from './text.APP_TARGET';
import PropTypes from 'prop-types';
import withTrack from 'HOCs/withTrack/withTrack';

// const PWA_BRANCH = `${process.env.PWA_BRANCH}`;
const VERSION = `v${process.env.PWA_VERSION}`;

export const EnvironmentLabel = props => {
    const { environment } = props.app;
    const branchName = `(VERSION: ${VERSION})`;

    return environment === 'production'
        ? null
        : <span className={ css.envLabel } data-aut-id="environmentLabel">{ environment } - { text } - <span>{ branchName}</span></span>;
};

EnvironmentLabel.propTypes = {
    app: PropTypes.object.isRequired
};

export default withTrack(EnvironmentLabel);
